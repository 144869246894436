import React from 'react';
import styled from 'styled-components';
import { NextPage, GetServerSideProps, GetServerSidePropsContext } from 'next';
import { Link as StyledLink } from '@makeship/core';
import Image from 'next/image';
import Link from 'next/link';
import posthog from 'posthog-js';
import Container from '../components/Container';
import Head from '../components/Head';
import Carousel from '../components/Carousel';
import ProductCard from '../components/ProductCard';

import { getCollectionByHandle, getCollectionByHandleDetailed } from '../api/collections';
import { getEndingSoonProducts, getCollectionProducts, filterProducts } from '../utils/collections';
import AtMakeship from '../components/pages/home/AtMakeship';
import ComingSoon from '../components/pages/home/ComingSoon';
import Community from '../components/pages/home/Community';
import config from '../../config.json';
import Masthead from '../components/pages/home/Masthead';
import { isUserLoggedIn } from '../utils/analytics';
import { useStore } from '../store';
import { H5, S1 } from '../components/Typography';
import { getPropsForPageLoad } from '../utils/pageLoad';

const { collections } = config;

const HomeContainer = styled(Container)`
  padding: 0 64px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0 16px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 0 8px;
  }
`;

const CarouselWrapper = styled.div`
  margin: 26px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 52px 0 16px 0;
  }
`;

const FeatureWrapper = styled.div`
  display: flex;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin: 0 8px;
  }
`;

const ProductFeature = styled.div<{ first: boolean }>`
  position: relative;
  width: 640px;
  height: 640px;
  margin: 32px 0;
  ${({ first }) => !first && `margin-left: 32px;`}
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  cursor: pointer;
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.containerWidth}px) {
    height: calc(50vw - 80px);
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 16px 0;
    width: 100%;
    height: calc(100vw - 32px);
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    height: calc(100vw - 16px);
  }
`;

const ProductFeatureLink = styled(StyledLink.PrimaryButton)`
  z-index: 1;
  margin-bottom: 14px;
`;

const PetitionsBannerWrapper = styled.div`
  margin: 50px 0 24px 0;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(187deg, rgba(83, 74, 238, 0.75) 24.79%, rgba(255, 50, 124, 0.75) 174.53%), #fff;
  border-radius: 14px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const PetitionsContentWrapper = styled.div`
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PetitionsTitle = styled(H5)`
  color: ${({ theme }) => theme.colors.neutral1};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    font-size: 36px;
    line-height: 48px;
  }
`;

const NewText = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
`;

const PetitionsDescription = styled(S1)`
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral1};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    font-size: 20px;
  }
`;

const PetitionsCTA = styled(StyledLink.PrimaryButton)`
  margin-top: 16px;
  z-index: 1;
`;

const MobilePetitionsGraphic = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: none;
  }
`;

const DesktopPetitionsGraphic = styled.div`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: block;
  }
`;

type HomePageProps = {
  masthead: Shopify.Collection | null;
  manualMasthead: Shopify.Collection | null;
  liveCampaigns: Shopify.ProductEdge[] | null;
  endingSoon: Shopify.ProductEdge[] | null;
  comingSoon: Shopify.ProductEdge[] | null;
  petitions: Shopify.ProductEdge[] | null;
  features: (Shopify.Collection | null)[];
};

const HomePage: NextPage<HomePageProps> = ({
  masthead,
  manualMasthead,
  liveCampaigns,
  endingSoon,
  comingSoon,
  features,
  petitions,
}: HomePageProps) => {
  const { state } = useStore();
  const { routes } = config;
  const isEndingSoonCarousel = false;
  const showEvergreenInCarousel = false;
  return (
    <>
      <Head title="Makeship" url="/" hideName />
      <Masthead masthead={masthead} manualMasthead={manualMasthead} />
      <HomeContainer>
        {liveCampaigns && liveCampaigns.length > 0 && (
          <CarouselWrapper>
            <Carousel
              title="Live Campaigns"
              link={`/shop/${collections.liveCampaigns}`}
              isInCollection
              isOnMainPage
              collectionForTracking={liveCampaigns}
              carouselLocation="home"
            >
              {filterProducts(liveCampaigns, false, false, true).map(({ node }) => (
                <ProductCard key={node.id} product={node} titleForTracking="Live Campaigns" />
              ))}
            </Carousel>
          </CarouselWrapper>
        )}
        {petitions && petitions.length > 0 && (
          <>
            <PetitionsBannerWrapper>
              <MobilePetitionsGraphic>
                <Image
                  src="/assets/petitions-make-a-pledge-2x.png"
                  width={300}
                  height={197}
                  alt="Bring an idea to life"
                />
              </MobilePetitionsGraphic>
              <DesktopPetitionsGraphic>
                <Image
                  src="/assets/petitions-fiya-concept-2x.png"
                  width={245}
                  height={245}
                  alt="Bring an idea to life"
                />
              </DesktopPetitionsGraphic>
              <PetitionsContentWrapper>
                <PetitionsTitle>
                  <NewText>New!</NewText> Petitions
                </PetitionsTitle>
                <PetitionsDescription>Where communities bring creator ideas to life! 🚀</PetitionsDescription>
                <Link href={routes.shopPetitions} passHref>
                  <PetitionsCTA onClick={() => posthog.capture('home_petitionsBanner_CTA')}>
                    Browse Petitions
                  </PetitionsCTA>
                </Link>
              </PetitionsContentWrapper>
              <DesktopPetitionsGraphic>
                <Image src="/assets/petitions-fiya-plush-2x.png" width={245} height={245} alt="Bring an idea to life" />
              </DesktopPetitionsGraphic>
            </PetitionsBannerWrapper>

            <CarouselWrapper>
              <Carousel
                title="Live Petitions"
                link={`/shop/${collections.petitions}`}
                isInCollection
                isOnMainPage
                collectionForTracking={petitions}
                carouselLocation="home"
              >
                {getCollectionProducts(petitions, isEndingSoonCarousel, showEvergreenInCarousel).map(({ node }) => (
                  <ProductCard key={node.id} product={node} titleForTracking="Live Petitions" />
                ))}
              </Carousel>
            </CarouselWrapper>
          </>
        )}
        {features && (
          <FeatureWrapper>
            {features.map(
              (feature, index) =>
                feature && (
                  <Link key={feature.handle} href={`/shop/${feature.handle}`} passHref>
                    <ProductFeature
                      first={index === 0}
                      onClick={() =>
                        posthog.capture('home_exploreFeature_click', {
                          link_handle: feature.handle,
                          link_href: `/shop/${feature.handle}`,
                          is_logged_in: isUserLoggedIn(state.user),
                        })
                      }
                    >
                      <Image
                        layout="fill"
                        objectFit="contain"
                        src={feature.image?.transformedSrc}
                        role="presentation"
                        alt=""
                        quality={90}
                        unoptimized
                      />
                      <Link href={`/shop/${feature.handle}`} passHref>
                        <ProductFeatureLink>Explore {feature.title}</ProductFeatureLink>
                      </Link>
                    </ProductFeature>
                  </Link>
                ),
            )}
          </FeatureWrapper>
        )}
        {endingSoon && endingSoon.length > 0 && (
          <CarouselWrapper>
            <Carousel
              title="Ending Soon"
              link="/shop/ending-soon"
              isInCollection
              isOnMainPage
              collectionForTracking={endingSoon}
              carouselLocation="home"
            >
              {getCollectionProducts(endingSoon, true).map(({ node }) => (
                <ProductCard key={node.id} product={node} titleForTracking="Ending Soon" />
              ))}
            </Carousel>
          </CarouselWrapper>
        )}
      </HomeContainer>
      <ComingSoon comingSoon={comingSoon} />
      <Community />
      <AtMakeship />
    </>
  );
};

export const getServerSideProps: GetServerSideProps<HomePageProps> = async (ctx: GetServerSidePropsContext) => {
  const { pagination } = config;
  const propsForPageLoad = await getPropsForPageLoad(ctx);

  const [
    masthead,
    manualMasthead,
    liveCampaigns,
    comingSoon,
    endingSoon,
    petitions,
    plushies,
    topCampaigns,
  ] = await Promise.all([
    getCollectionByHandleDetailed(collections.automatedMasthead, undefined, pagination.automatedMasthead),
    getCollectionByHandleDetailed(collections.masthead, undefined, pagination.manualMasthead),
    getCollectionByHandle(collections.liveCampaigns).then(
      (collection) => (collection?.products?.edges as Shopify.ProductEdge[]) || null,
    ),
    getCollectionByHandle(collections.comingSoon).then(
      (collection) => (collection?.products?.edges as Shopify.ProductEdge[]) || null,
    ),
    getEndingSoonProducts(collections.liveCampaigns),
    getCollectionByHandle(collections.petitions).then(
      (collection) => (collection?.products?.edges as Shopify.ProductEdge[]) || null,
    ),
    getCollectionByHandle(collections.plushies),
    getCollectionByHandle(collections.topCampaigns),
  ]);

  return {
    props: {
      masthead,
      manualMasthead,
      liveCampaigns,
      comingSoon,
      endingSoon,
      petitions,
      features: [plushies, topCampaigns],
      ...propsForPageLoad,
    },
  };
};

export default HomePage;
